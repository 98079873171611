<template>
  <main class="site-main">
    <section class="container search-section">
      <h1>검색 결과</h1>
      <ul class="search-header-links">
        <!--        <li><a href="#">추천 검색 1</a></li>-->
        <!--        <li>추천 검색 2</li>-->
        <!--        <li>추천 검색 3</li>-->
      </ul>
      <div class="search-container">
        <div class="search-bar">
          <div class="text-field">
            <input type="text" placeholder="검색어를 입력해 주세요" class="search-txt" @keyup.enter="searchByKeyword"
                   v-model="keyword">
          </div>
          <button type="submit" class="search-submit search-results-found" @click="searchByKeyword">
            <i class="icon icon-search">
              <svg class="icon-svg" viewBox="0 0 50 50">
                <title>Search Icon</title>
                <path
                    d="M47.4,39.67,38.6,30.88a20.1,20.1,0,1,0-7.73,7.73l8.79,8.79a5.47,5.47,0,1,0,7.73-7.73ZM4.32,21.08A16.76,16.76,0,1,1,21.08,37.84,16.78,16.78,0,0,1,4.32,21.08ZM45,45.05a2.2,2.2,0,0,1-3,0l-8.34-8.34a20.26,20.26,0,0,0,3-3L45.05,42a2.14,2.14,0,0,1,0,3Z">
                </path>
                <path
                    d="M21.08,8A13.07,13.07,0,1,0,34.15,21.08,13.08,13.08,0,0,0,21.08,8Zm0,22.81a9.75,9.75,0,1,1,9.75-9.75A9.76,9.76,0,0,1,21.08,30.83Z">
                </path>
              </svg>
            </i>
          </button>
          <div class="search-bar-error-text" v-if="showErrorText">1글자 이상 입력해 주세요.</div>
        </div>
        <div class="search-results-found-text">
<!--          검색 결과 :-->
<!--          <span class="total-results" v-if="keyword !== ''">{{ totalElements }}</span>-->
<!--          <span class="total-results" v-else>0</span>-->
<!--          건-->
        </div>

        <Menu3Depth :selectTab="selectTab" @selected-tab="setSelectTab" :menu-list="menu"/>

        <div class="search-results">
          <template v-if="listType === 'boardList'">
            <div class="search-result" v-for="(item, index) in boardList" :key="index">
              <div class="search-result-body">
                <router-link :to=item.link class="pod-title" target="_self">{{ item.title }}</router-link>
                <p class="body-1">{{ item.content }}</p>
              </div>
            </div>
            <!--more버튼-->
            <div class="btn_area btn_more" v-if="!boardIsLast && keyword">
              <button @click="loadMoreBoard">Load More</button>
            </div>
            <div class="search-results-none" v-if="boardTotalElements<=0">
              <div class="rte">
                <h3>검색 결과를 찾을 수 없습니다.</h3>
                <p>다시 검색 해 주세요.</p>
              </div>
            </div>
          </template>
          <template v-if="listType === 'webinarList'">
            <div class="search-result" v-for="(item, index) in webinarList" :key="index">
              <div class="search-result-body">
                <router-link :to=item.link class="pod-title" target="_self">{{ item.title }}</router-link>
                <p class="body-1">{{ item.description }}</p>
              </div>
            </div>

            <!--more버튼-->
            <div class="btn_area btn_more" v-if="!webinarIsLast && keyword">
              <button @click="loadMoreWebinar">Load More</button>
            </div>
            <div class="search-results-none" v-if="webinarTotalElements <= 0">
              <div class="rte">
                <h3>검색 결과를 찾을 수 없습니다.</h3>
                <p>다시 검색 해 주세요.</p>
              </div>
            </div>
          </template>
          <template v-if="listType === 'surveyList'">
            <div
                class="search-result"
                v-for="(item, index) in surveyList"
                :key="index">
              <div class="search-result-body">
                <router-link :to=item.link class="pod-title" target="_self">{{ item.title }}</router-link>
                <p class="body-1">{{ item.content }}</p>
              </div>
            </div>

            <!--more버튼-->
            <div class="btn_area btn_more" v-if="!surveyIsLast && keyword">
              <button @click="loadMoreSurvey">Load More</button>
            </div>
            <div class="search-results-none" v-if="surveyTotalElements <= 0">
              <div class="rte">
                <h3>검색 결과를 찾을 수 없습니다.</h3>
                <p>다시 검색 해 주세요.</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Menu3Depth from "@/components/search/SearchMenu3Depth";
import boardAPI from "@/api/boardAPI";
import webinarAPI from "@/api/webinarAPI";
import surveyAPI from "@/api/surveyAPI";

export default {
  name: "searchResultPage",
  components: {
    Menu3Depth
  },
  props: {
    searchKeyword: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.showErrorText = false
    this.searchByKeyword()
  },
  computed: {
    menu() {
      return [
        {
          name: `콘텐츠(${this.boardTotalElements})`,
        },
        {
          name: `웨비나(${this.webinarTotalElements})`,
        },
        {
          name: `설문조사(${this.surveyTotalElements})`,
        },
      ]
    }
  },
  data() {
    return {
      keyword: this.searchKeyword??this.$route.query.keyword,
      showErrorText: false,
      selectTab: '-1',
      listType: "",
      isListLength: true,
      totalElements: 0,
      isLast: false,

      boardList: [],
      boardIsLast: false,
      boardPage: 0,
      boardTotalElements: 0,

      webinarList: [],
      webinarIsLast: false,
      webinarPage: 0,
      webinarTotalElements: 0,

      surveyList: [],
      surveyIsLast: false,
      surveyPage: 0,
      surveyTotalElements: 0,
    }
  },
  watch: {
    // keyword() {
    //   this.$router.replace({
    //     query: {
    //       keyword: this.keyword
    //     }
    //   })
    // },
    selectTab() {
      if (this.selectTab === 0) {
        this.listType = "boardList"
        this.totalElements = this.boardTotalElements
      } else if (this.selectTab === 1) {
        this.listType = "webinarList"
        this.totalElements = this.webinarTotalElements
      } else if (this.selectTab === 2) {
        this.listType = "surveyList"
        this.totalElements = this.surveyTotalElements
      }
    }
  },
  methods: {
    setSelectTab(selectTab) {
      this.selectTab = selectTab
    },
    loadMoreBoard() {
      boardAPI.searchBoardByKeyword({
        description: this.keyword,
        page: this.boardPage + 1
      }).then(({data: {value: {content, last, number, totalElements}}}) => {
        this.boardList = [...this.boardList, ...content.map(board => {
          switch (board.type) {
            case 'social-contribute':
              board['link'] = `/news/social-contribute/${board['link']}`
              break;
            case 'brochure':
              board['link'] = `/menu/${board['productName']}/brochure/${board['link']}`
              break;
            case 'news-and-notice':
              board['link'] = `/news/news-and-notice/${board['link']}`
              break;
            case 'symposium-vod':
              board['link'] = `/webinar-symposium/symposium-vod/${board['link']}`
              break;
            case 'related-disease':
              board['link'] = `/menu/${board['productName']}/related-disease/${board['link']}`
              break;
            case 'clinical-trial':
              board['link'] = `/menu/${board['productName']}/clinical-trial/${board['link']}`
              break;
            default:
              break;
          }
          return board
        })]
        this.boardIsLast = last
        this.boardPage = number
        this.boardTotalElements = totalElements
        this.isListLength = totalElements > 0
      })
    },
    loadMoreWebinar() {
      webinarAPI.searchWebinarByKeyword({
        description: this.keyword,
        page: this.webinarPage + 1
      }).then(({data: {value: {content, last, number, totalElements}}}) => {
        this.webinarList = [...this.boardList, ...content]
        this.webinarIsLast = last
        this.webinarPage = number
        this.webinarTotalElements = totalElements
      })
    },
    loadMoreSurvey() {
      surveyAPI.searchSurveyByKeyword({
        description: this.keyword,
        page: this.surveyPage + 1
      }).then(({data: {value: {content, last, number, totalElements}}}) => {
        this.surveyList = [...this.surveyList, ...content.map(survey => {
          survey['link'] = `/news/survey-event/${survey['link']}`
          return survey
        })]
        this.surveyIsLast = last
        this.surveyPage = number
        this.surveyTotalElements = totalElements
      })
    },
    searchByKeyword() {
      this.showErrorText = false
      this.isListLength = false //검색했을때 리스트가 없는거로 초기화
      this.selectTab = 0

      if (this.keyword === "") {
        this.showErrorText = true //검색창에 글자를 입력하지 않았을 경우
        this.isListLength = false //검색했을때 리스트가 없는거로 초기화
        this.totalElements = 0 //검색결과 개수 초기화
        //키워드 입력 안하면 모든 리스트 초기화
        this.boardList = []
        this.webinarList = []
        this.surveyList = []
        return false
      }

      boardAPI.searchBoardByKeyword({
        description: this.keyword,
      }).then(({data: {value: {content, last, number, totalElements}}}) => {
        this.boardList = content
        this.boardIsLast = last
        this.boardPage = number
        this.boardTotalElements = totalElements
        this.isListLength = totalElements > 0
        this.totalElements = totalElements
        this.boardList = this.boardList.map(board => {
          switch (board.type) {
            case 'social-contribute':
              board['link'] = `/news/social-contribute/${board['link']}`
              break;
            case 'brochure':
              board['link'] = `/menu/${board['productName']}/brochure/${board['link']}`
              break;
            case 'news-and-notice':
              board['link'] = `/news/news-and-notice/${board['link']}`
              break;
            case 'symposium-vod':
              board['link'] = `/webinar-symposium/symposium-vod/${board['link']}`
              break;
            case 'related-disease':
              board['link'] = `/menu/${board['productName']}/related-disease/${board['link']}`
              break;
            case 'clinical-trial':
              board['link'] = `/menu/${board['productName']}/clinical-trial/${board['link']}`
              break;
            default:
              break;
          }
          return board
        })
      })

      webinarAPI.searchWebinarByKeyword({
        description: this.keyword,
      }).then(({data: {value: {content, last, number, totalElements}}}) => {
        this.webinarList = content
        this.webinarIsLast = last
        this.webinarPage = number
        this.webinarTotalElements = totalElements
        this.webinarList = this.webinarList.map(webinar => {
          webinar['link'] = `/webinar-symposium/webinar-live/${webinar['link']}`
          return webinar
        })
      })

      surveyAPI.searchSurveyByKeyword({
        description: this.keyword,
      }).then(({data: {value: {content, last, number, totalElements}}}) => {
        this.surveyList = content
        this.surveyIsLast = last
        this.surveyPage = number
        this.surveyTotalElements = totalElements
        this.surveyList = this.surveyList.map(survey => {
          survey['link'] = `/news/survey-event/${survey['link']}`
          return survey
        })
      })
    },
  }
}
</script>

<style scoped>

</style>

<!--          <div class="search-result">-->
<!--            <div class="search-result-body">-->
<!--              <a href="#" class="pod-title">검색결과타이틀</a>-->
<!--              <p class="body-1">검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다.-->
<!--                검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다. </p>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="search-result">-->
<!--            <div class="search-result-body">-->
<!--              <a href="#" class="pod-title" target="_self">검색결과타이틀</a>-->
<!--              <p class="body-1">검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다.-->
<!--                검색결과내용이 들어갑니다. 검색결과내용이 들어갑니다. </p>-->
<!--            </div>-->
<!--          </div>-->
