<template>
  <v-sheet elevation="6" color="#F6F4F3">
    <v-tabs
        show-arrows
        background-color="#F6F4F3"
        centered
        center-active
        slider-size="6"
        slider-color="#BE2BBB"
        height="66"
        style="max-width: 1100px; margin: 0 auto;"
        mobile-breakpoint="720"
        fixed-tabs
        optional
        v-model="selectedTab"
    >
      <v-tab
          v-for="(menu, index) in menuList"
          :key="index"
          v-text="menu.name"
      >
      </v-tab>
    </v-tabs>
  </v-sheet>
</template>

<script>
export default {
  name: "SearchMenu3Depth",
  props: {
    selectTab: {
      default: '-1'
    },
    menuList: {
      type : Array
    }
  },
  computed: {
    selectedTab: {
      get() {
        return this.selectTab;
      },
      set(value) {
        this.$emit('selected-tab',value);
      }
    },
    // menuList() {
    //   return [
    //     {
    //       name: '보드',
    //     },
    //     {
    //       name: '웨비나',
    //     },
    //     {
    //       name: '설문조사',
    //     },
    //
    //   ];
    // }
  },
}
</script>

<style scoped>

</style>
